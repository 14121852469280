import { isString } from '@yescapa-dev/ysc-api-js/modern'
import { YscApiError } from './YscApiError'
import type { YscApiErrorParams } from './YscApiError'
import { PAYMENT_CAMPER_ALREADY_BOOKED, PAYMENT_BOOKING_ALREADY_PAID, PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END, PAYMENT_ERROR_IN_SEND_CARD_INFO, PAYMENT_INSURANCE_CONDITIONS_NOT_MET, PAYMENT_INVALID_BOOKING_STATE, PAYMENT_UNKNOWN } from './errorFingerprints'

const regexPaymentConfirm = /^\/3\/my-requests\/\d+\/payment\/confirm\//
export class YscApiPaymentError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    let fingerprint = PAYMENT_UNKNOWN

    if (this.apiMethod === 'post' && this.apiStatus === 405 && this.apiEndpoint && regexPaymentConfirm.test(this.apiEndpoint)) {
      if (typeof args.apiResponseErrorData === 'object' && args.apiResponseErrorData !== null && 'code' in args.apiResponseErrorData && isString(args.apiResponseErrorData.code))
        switch (args.apiResponseErrorData.code) {
          case 'PAYMENT_ERROR_CREDIT_CARD_WILL_EXPIRE':
            fingerprint = PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END
            break
          case 'BOOKING_IN_WRONG_STATE':
            fingerprint = PAYMENT_INVALID_BOOKING_STATE
            break
          case 'CAMPER_ALREADY_BOOKED':
            fingerprint = PAYMENT_CAMPER_ALREADY_BOOKED
            break
          case 'INSURANCE_CONDITIONS_NOT_MET':
            fingerprint = PAYMENT_INSURANCE_CONDITIONS_NOT_MET
            break
          case 'BOOKING_ALREADY_PAID':
            fingerprint = PAYMENT_BOOKING_ALREADY_PAID
            break
          case 'PAYMENT_ERROR_IN_SEND_CARD_INFO':
            fingerprint = PAYMENT_ERROR_IN_SEND_CARD_INFO
            break
        }
    }
    this.fingerprint = fingerprint
  }
}
