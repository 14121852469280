import validate from "/home/node/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45header_45version_45global from "/home/node/app/middleware/00.header-version.global.ts";
import _01_45send_45events_45global from "/home/node/app/middleware/01.send-events.global.ts";
import manifest_45route_45rule from "/home/node/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45header_45version_45global,
  _01_45send_45events_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "authenticated-to-signin": () => import("/home/node/app/middleware/authenticated-to-signin.ts"),
  "authenticated-to-signup": () => import("/home/node/app/middleware/authenticated-to-signup.ts"),
  "dashboard-account-redirections": () => import("/home/node/app/middleware/dashboard-account-redirections.ts"),
  "dashboard-document-id-fetch-mandatory-data": () => import("/home/node/app/middleware/dashboard-document-id-fetch-mandatory-data.ts"),
  "dashboard-root-redirection": () => import("/home/node/app/middleware/dashboard-root-redirection.ts"),
  "force-page-one-if-empty": () => import("/home/node/app/middleware/force-page-one-if-empty.ts"),
  staff: () => import("/home/node/app/middleware/staff.ts")
}