import type { CardRegistrationError } from '~/utils/error/CardRegistrationError'
import { YscCardRegistrationError } from '~/utils/error/YscCardRegistrationError'
import { YSC_CARD_REGISTRATION_ERROR } from '~/utils/error/YscErrorClasses'

export function useHandleCardRegistrationError() {
  const { $i18n: { locale } } = useNuxtApp()
  const report = useErrorReport()
  const route = useRoute()

  return ({ e }: { e: CardRegistrationError }) => {
    const { message, code, errorType } = e

    const yscError = new YscCardRegistrationError({
      message,
      code,
      errorType,
      locale: toValue(locale),
      route: route?.fullPath,
      name: YSC_CARD_REGISTRATION_ERROR,
    })

    report(yscError)

    return yscError
  }
}
