import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type {
  AvailabilitiesListItemResponse,
  VehicleResponse,
  ListResponse,
  Camper,
  MineFiltersResponse,
  CamperDeleteQueryParameters,
  ShortDocument,
  VehiclePatchPayload,
  ImageType,
  CamperCalendarPriceInstance,
  CamperCreateCalendarPricePayload,
  CamperPriceDetails,
  CamperUpdateCalendarPricePayload,
  CamperUpdatePriceDetails,
  CamperCreateOptionPayload,
  CamperPreferencesForm,
  CamperCalendarPriceListResponse,
  CamperOptionPayload,
  CamperResponseItem,
  ParsedCamper,
  FetchCamperQueryParameters,
} from '@yescapa-dev/ysc-api-js/legacy'
import { OWNER_ACCEPTANCE_STATES } from '@yescapa-dev/ysc-api-js/modern'
import { addMonths, endOfMonth } from 'date-fns'
import { DEFAULT_CAMPERS_PAGE_SIZE } from '~/constants/campers'

// Minimal infos needed for an onboarding
export const vehicleFactory = () => ({
  id: null,
  type: null,
  seatbelts: null,
  beds: null,
  registration: null,
  registration_country: null,
  value: null,
  date: null,
  gvw: null,
  height: null,
  location: {
    zipcode: null,
    street: null,
    city: null,
    country: null,
    latitude: null,
    longitude: null,
  },
  photos: [],
})

export const useCamperStore = defineStore('camper', () => {
  const { $api } = useYscApi()

  const camper = ref<Camper | null>(null)
  const camperAvailabilities = ref<{
    camperId: Camper['id']
    list: AvailabilitiesListItemResponse[]
  }>()
  const vehicle = ref<VehicleResponse | null>(null)

  const all = ref<ListResponse<ParsedCamper<CamperResponseItem>>>({ count: 0, results: [] })
  const filters = ref<MineFiltersResponse | null>(null)

  const prices = ref<CamperCalendarPriceListResponse | null>(null)

  const priceDetails = ref<CamperPriceDetails | null>(null)

  const allIncomplete = computed(() => all.value.results.filter(({ published, publishable }) => !published && !publishable))

  const hasOnlyOneCamper = computed(() => all.value.count === 1)

  const isCamperRegistrationCertified = computed(() => {
    if (!camper.value) {
      return false
    }
    if (!Array.isArray(camper.value.documents)) {
      return false
    }

    const carRegistration = camper.value.documents.find(
      ({ document_type }) => document_type === Constants.DOCUMENTS.TYPE.CAR_REGISTRATION,
    )

    return carRegistration?.status === Constants.DOCUMENTS.STATUS.VALIDATED
  })

  const hasInternationalOptions = computed(() => {
    return camper.value?.abroad_allowed !== OWNER_ACCEPTANCE_STATES.NOT_ALLOWED
  })

  const hasInstantBookingActivated = computed(() => {
    return Boolean(camper.value?.instant_booking_activated)
  })

  const fetchCamper = async ({ id, refresh, params }: { id: number, refresh?: boolean, params?: FetchCamperQueryParameters }) => {
    if (!refresh && id === camper.value?.id) {
      return Promise.resolve()
    }

    camper.value = await $api.products.get(id, params)
  }

  const fetchCurrentCamperAvailabilities = async ({ refresh }: { refresh?: boolean } = { refresh: false }) => {
    if (!camper.value) throw new Error('Trying to fetch current camper availabilities but not camper is loaded')
    if (!refresh && camper.value.id === camperAvailabilities.value?.camperId) {
      return Promise.resolve()
    }

    const today = new Date()

    camperAvailabilities.value = {
      camperId: camper.value.id,
      list: await $api.products.getAvailabilities(camper.value.id, {
        date_from: today,
        date_to: endOfMonth(addMonths(today, camper.value.max_months_future_rental)),
      }),
    }
  }

  const fetchVehicle = async ({ id, refresh }: { id?: number, refresh?: boolean } = {}) => {
    id = id || camper.value?.vehicle_pk
    if (!id || (!refresh && id === vehicle.value?.id)) {
      return Promise.resolve()
    }
    vehicle.value = await $api.vehicles.get(id)
  }

  const fetchCampers = async ({ page = 1, page_size = DEFAULT_CAMPERS_PAGE_SIZE, city, refresh = false }: { page?: number, page_size?: number, city?: string, refresh?: boolean } = {}) => {
    if (!refresh && all.value.count && !city) {
      return Promise.resolve()
    }
    const { count, results } = await $api.products.getMine({ page, page_size, city })
    all.value = { count, results }
  }

  const fetchCampersFilters = async () => {
    if (filters.value) {
      return Promise.resolve()
    }
    filters.value = await $api.products.getMineFilters()
  }

  const camperDocuments = computed(() => {
    const documents: ShortDocument[] = []

    const userDocuments = useUserStore().user?.documents || []
    const idProof = userDocuments.find(({ document_type }) => document_type === Constants.DOCUMENTS.TYPE.ID_PROOF)
    if (idProof) {
      documents.push(idProof)
    }

    const { CAR_REGISTRATION, CAR_PROOF_INSURANCE } = Constants.DOCUMENTS.TYPE
    const camperDocuments = (camper.value?.documents || []).filter(({ is_mandatory }) => is_mandatory)
    documents.push(
      ...sortArrayByProps(camperDocuments, 'document_type', [
        CAR_REGISTRATION,
        CAR_PROOF_INSURANCE,
      ]),
    )

    return documents
  })

  const isCamperDocumentsUploaded = () => {
    return everyDocumentIsUploaded(camperDocuments.value)
  }

  const isCamperDocumentsValidated = () => {
    return everyDocumentIsValidated(camperDocuments.value)
  }

  const reactivateCamper = async () => {
    if (!camper.value) {
      return
    }
    await $api.products.reactivate(camper.value.id)
    camper.value = await $api.products.get(camper.value.id)
  }

  const suspendCamper = async (form: CamperDeleteQueryParameters) => {
    if (!camper.value) {
      return
    }
    await $api.products.suspend(camper.value.id, form)
    camper.value = await $api.products.get(camper.value.id)
  }

  const setCamperVehicle = (payload) => {
    if (!payload) {
      payload = vehicleFactory()
    }
    vehicle.value = payload
  }

  const resetCamper = () => {
    camper.value = null
    vehicle.value = vehicleFactory()
    prices.value = null
    // price details = null
  }

  const updateCamperVehicle = async (payload: VehiclePatchPayload) => {
    if (!vehicle.value) {
      return
    }
    const { $api } = useYscApi()
    vehicle.value = await $api.vehicles.update(vehicle.value.id, payload)
  }

  /*
  * Used in onboarding
  * User can save his vehicle in different states.
    - user not logged (step 1)
    - user logged and vehicle is stored in the browser localStorage (default onboarding step 1, 2, 3)
    - user logged and vehicle is stored remotly (uncomplete onboarding step 1, 2, 3, 4)
  */
  const { storedVehicle } = useStoredVehicle()
  const isSavingCamperVehicle = ref(false)
  const saveCamperVehicle = async ({ create = false, fields = [] }: { create?: boolean, fields?: (keyof VehiclePatchPayload)[] } = {}) => {
    if (vehicle.value) {
      if (!vehicle.value?.id && create) {
        const { $api } = useYscApi()
        isSavingCamperVehicle.value = true
        vehicle.value = await $api.vehicles.create(vehicle.value)
        isSavingCamperVehicle.value = false
        storedVehicle.value = null
        return
      }

      const { isLoggedIn } = storeToRefs(useUserStore())

      if (vehicle.value.id && isLoggedIn.value) {
        const data = fields.reduce((acc: VehiclePatchPayload, cur: keyof VehiclePatchPayload) => {
          if (vehicle.value) {
            acc[cur] = vehicle.value[cur]
          }
          return acc
        }, {})
        isSavingCamperVehicle.value = true
        await updateCamperVehicle(data)
        isSavingCamperVehicle.value = false
        return
      }
    }

    storedVehicle.value = vehicle.value
  }

  const uploadCamperVehiclePhoto = async (form: FormData) => {
    if (!vehicle.value) {
      return
    }
    const { $api } = useYscApi()
    const photo = await $api.vehicles.uploadPhoto(vehicle.value.id, form)
    addCamperVehiclePhoto(photo)
  }

  const updateCamperVehiclePhoto = async (form: FormData, image_id: string) => {
    if (!vehicle.value) {
      return
    }
    const { $api } = useYscApi()
    const photo = await $api.vehicles.updatePhoto(vehicle.value.id, image_id, form)
    setCamperVehiclePhoto(photo)
  }

  const rotateCamperVehiclePhoto = async (image_id: string) => {
    if (!vehicle.value) {
      return
    }
    const { $api } = useYscApi()
    const photo = await $api.vehicles.rotatePhoto(vehicle.value.id, image_id)
    setCamperVehiclePhoto(photo)
  }

  const deleteCamperVehiclePhoto = async (image_id: string) => {
    if (!vehicle.value) {
      return
    }
    const { $api } = useYscApi()
    await $api.vehicles.deletePhoto(vehicle.value.id, image_id)
    removeCamperVehiclePhoto(image_id)
  }

  const addCamperVehiclePhoto = (photo: ImageType) => {
    if (!vehicle.value) {
      return
    }
    vehicle.value.photos.push(photo)
    vehicle.value.photos.sort((a, b) => a.index - b.index)
  }

  const setCamperVehiclePhoto = (photo: ImageType) => {
    if (!vehicle.value) {
      return
    }
    const i = vehicle.value.photos.findIndex(({ index }) => index === photo.index)
    vehicle.value.photos[i] = photo
  }

  const removeCamperVehiclePhoto = (idPhoto: string) => {
    if (!vehicle.value) {
      return
    }
    const photos = vehicle.value.photos.filter(({ id }) => id !== idPhoto)
    vehicle.value.photos = photos
  }

  const createCamperPrice = async ({ data }: { data: CamperCreateCalendarPricePayload }) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    const price = await $api.products.createPrice(camper.value.id, data)
    addCamperPrice(price)
  }

  const updateCamperPrice = async ({ data }: { data: CamperUpdateCalendarPricePayload & { id: number } }) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    const price = await $api.products.updatePrice(camper.value.id, data.id, data)
    setCamperPrice(price)
  }

  const setCamperPrice = (price: CamperCalendarPriceInstance) => {
    if (!prices.value) {
      return
    }
    const index = prices.value.findIndex(p => p.id === price.id)
    prices.value[index] = price
  }

  const addCamperPrice = (price: CamperCalendarPriceInstance) => {
    if (!prices.value || !Array.isArray(prices.value)) {
      prices.value = []
    }
    prices.value.push(price)
  }

  const updateCamperPreferences = async (payload: CamperPreferencesForm) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    camper.value = await $api.products.updatePreferences(camper.value.id, payload)
  }

  const createCamperOptions = async (payload: CamperCreateOptionPayload) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    camper.value.options = await $api.products.createOptions(camper.value.id, payload)
  }

  const updateCamperOptions = async (payload: Partial<CamperCreateOptionPayload>) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    camper.value.options = await $api.products.updateOptions(camper.value.id, payload)
  }

  const deleteCamperOptions = async (payload: CamperOptionPayload) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    camper.value.options = await $api.products.deleteOptions(camper.value.id, payload)
  }

  const updateCamperPriceDetails = async (form: CamperUpdatePriceDetails) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    priceDetails.value = await $api.products.updatePricesDetails(camper.value.id, form)
  }

  const fetchCamperPrices = async ({ id, refresh = false }: { id?: string | number, refresh?: boolean }) => {
    if (!refresh && prices.value) {
      return Promise.resolve()
    }
    id = id ? +id : camper.value?.id
    if (!id) {
      return
    }
    const { $api } = useYscApi()
    prices.value = await $api.products.getPrices(id)
  }

  const fetchCamperPriceDetails = async ({ refresh = false }: { refresh?: boolean } = {}) => {
    if (!refresh && priceDetails.value) {
      return Promise.resolve()
    }
    if (!camper.value) {
      return
    }

    const { $api } = useYscApi()
    priceDetails.value = await $api.products.getPricesDetails(camper.value.id)
  }

  const removeCamperPrice = (idPrice: number | string) => {
    if (!prices.value) {
      return
    }
    prices.value = prices.value.filter(p => p.id !== idPrice)
  }

  const deleteCamperPrice = async ({ data }: { data: { id: number } }) => {
    if (!camper.value) {
      return
    }
    const { $api } = useYscApi()
    await $api.products.deletePrice(camper.value.id, data.id)
    removeCamperPrice(+data.id)
  }

  return {
    camper,
    camperAvailabilities,
    currentCamperAvailabilities: computed(() => camperAvailabilities.value?.list ?? []),
    vehicle,
    all,
    filters,
    prices,
    priceDetails,
    allIncomplete,
    isCamperRegistrationCertified,
    hasOnlyOneCamper,
    isSavingCamperVehicle,
    camperDocuments,
    hasInternationalOptions,
    hasInstantBookingActivated,
    fetchCamper,
    fetchCurrentCamperAvailabilities,
    fetchCampers,
    fetchVehicle,
    fetchCampersFilters,
    isCamperDocumentsUploaded,
    isCamperDocumentsValidated,
    reactivateCamper,
    suspendCamper,
    updateCamperPreferences,
    setCamperVehicle,
    resetCamper,
    saveCamperVehicle,
    updateCamperVehicle,
    uploadCamperVehiclePhoto,
    updateCamperVehiclePhoto,
    rotateCamperVehiclePhoto,
    deleteCamperVehiclePhoto,
    updateCamperPrice,
    createCamperPrice,
    fetchCamperPrices,
    fetchCamperPriceDetails,
    deleteCamperPrice,
    updateCamperPriceDetails,
    createCamperOptions,
    updateCamperOptions,
    deleteCamperOptions,
  }
})
