import type { CardRegistrationError } from './CardRegistrationError'
import { YSC_CARD_REGISTRATION_ERROR_CARD, YSC_CARD_REGISTRATION_ERROR_TOKEN, YSC_CARD_REGISTRATION_ERROR_UNKNOWN } from './errorFingerprints'
import { YscError } from './YscError'
import type { YscErrorParams } from './YscError'

export class YscCardRegistrationError extends YscError {
  code: string

  constructor({ code, errorType, ...superArgs }: YscErrorParams & Pick<CardRegistrationError, 'code' | 'errorType'>) {
    super(superArgs)
    this.code = code

    if (errorType === 'card') {
      this.fingerprint = YSC_CARD_REGISTRATION_ERROR_CARD
      return
    }

    if (errorType === 'token') {
      this.fingerprint = YSC_CARD_REGISTRATION_ERROR_TOKEN
      return
    }

    this.fingerprint = YSC_CARD_REGISTRATION_ERROR_UNKNOWN
  }

  override get sentryAdditionalData() {
    return {
      ...super.sentryAdditionalData,
      code: this.code,
    }
  }
}
