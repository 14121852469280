<script setup lang="ts">
export interface AppHeaderMenuProps {
  showAppLink?: boolean
}

withDefaults(defineProps<AppHeaderMenuProps>(), {
  showAppLink: false,
})

const { locale } = useI18n()
const { referential } = storeToRefs(useReferentialStore())
const mustShowGoboonyModal = computed(() =>
  !referential.value?.can_publish_camper,
)

const { goboonyCreateCamperUrl } = useGoboonyRedirectCreateCamper(locale)

const showGoboonyModal = ref(false)
const onRentYourVehicleClick = async () => {
  if (mustShowGoboonyModal.value) {
    showGoboonyModal.value = true
    return
  }
  else {
    await navigateTo({ name: 'onboarding-step' })
  }
}
</script>

<template>
  <div class="flex items-center justify-end space-x-1 sm:space-x-3 xl:space-x-10 xl:font-semibold">
    <NuxtLink
      v-if="showAppLink"
      id="get-apps"
      :to="{ name: 'get-apps' }"
      class="btn btn-small btn-tertiary text-xs px-3 sm:text-sm sm:px-6"
    >
      {{ $t('commons.links.get-apps') }}
    </NuxtLink>

    <NuxtLink
      v-if="showAppLink"
      id="rent-your-vehicle"
      :to="mustShowGoboonyModal ? undefined : { name: 'onboarding-step' }"
      class="font-normal text-black hidden sm:block"
      @click="onRentYourVehicleClick"
    >
      {{ $t('commons.links.onboarding_step') }}
    </NuxtLink>

    <AppMenuHelpDropdown class="hidden xl:block" />

    <AppMenuUserPanel
      class="xl:hidden"
      :show-app-link="showAppLink"
    />

    <AppMenuDropdown
      class="hidden xl:flex"
      :show-app-link="showAppLink"
    />
    <Teleport
      v-if="showGoboonyModal"
      to="body"
    >
      <AppModalYesboonyCreateCamper
        v-if="showGoboonyModal"
        :country="locale"
        :redirect-url="goboonyCreateCamperUrl"
        :can-close="true"
        @close="showGoboonyModal = false"
      />
    </Teleport>
  </div>
</template>
