import type { LocationQueryValue } from '#vue-router'

export const paymentErrorCodeSafeGuard = (error_code: LocationQueryValue | LocationQueryValue[]) => {
  const errorCode = queryValueToString(error_code)

  // For some case considered as error by the API we don't want to show the error page but just redirect to the payment form
  // for example with 3rd party solution such as Paypal, clicking the "Go back to yescapa" link shouldn't display an error page.
  const redirectToPaymentCodes: string[] = [
    '201004', // Paypal - PayPal account's owner has not approved payment
  ]

  // !errorCode -> Safeguard in case of dev error / direct access by user
  return (!errorCode || redirectToPaymentCodes.includes(errorCode))
}
