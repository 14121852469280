import { BOOKING_HOUR_FROM_AFTERNOON, BOOKING_HOUR_FROM_MORNING, BOOKING_HOUR_TO_AFTERNOON, BOOKING_HOUR_TO_MORNING, BOOKING_KM_OPTIONS } from '@yescapa-dev/ysc-api-js/modern'
import type { LocationQuery } from '#vue-router'

export const sanitizeCamperQuery = (query: LocationQuery) => {
  const allowedValues: SanitizeQueryAllowedValues = {
    date_from: {
      type: 'date',
    },
    date_to: {
      type: 'date',
    },
    hour_from: {
      type: 'number',
      values: [BOOKING_HOUR_FROM_MORNING, BOOKING_HOUR_FROM_AFTERNOON],
    },
    hour_to: {
      type: 'number',
      values: [BOOKING_HOUR_TO_MORNING, BOOKING_HOUR_TO_AFTERNOON],
    },
    kilometers: {
      type: 'number',
      values: [BOOKING_KM_OPTIONS.UNLIMITED, BOOKING_KM_OPTIONS.KM_100, BOOKING_KM_OPTIONS.KM_200],
    },
  }
  const report = sanitizeQuery(query, allowedValues)

  if ((!report.nextQuery.date_from || !report.nextQuery.date_to) && (report.nextQuery.hour_to || report.nextQuery.hour_from)) {
    delete report.nextQuery.hour_to
    delete report.nextQuery.hour_from
    report.mustRedirect = true
    report.validationErrorLogs.push('sanitizeCamperQuery: hours defined but no date range present')
  }

  return report
}
