import type { FormattedInsurance } from '~/types/insurances'

export const useBookingInsurances = () => {
  const insurances = ref<FormattedInsurance[] | null>(null)

  const basicInsurance = computed(() => {
    if (!Array.isArray(insurances.value)) {
      return null
    }

    return insurances.value.find(({ is_reinforced }: { is_reinforced: boolean }) => !is_reinforced) ?? null
  })

  const premiumInsurance = computed(() => {
    if (!Array.isArray(insurances.value)) {
      return null
    }

    return insurances.value.find(({ is_reinforced }: { is_reinforced: boolean }) => is_reinforced) ?? null
  })

  return {
    insurances,
    basicInsurance,
    premiumInsurance,
  }
}
