import type { YscApiErrorParams } from './YscApiError'
import { YscApiError } from './YscApiError'
import { REVIEW_REPORT, REVIEW_UNKNOWN } from './errorFingerprints'

const regexReviewReport = /\/3\/review\/[0-9]*\/report\//gm

export class YscApiReviewError extends YscApiError {
  constructor(args: YscApiErrorParams) {
    super(args)

    this.fingerprint = REVIEW_UNKNOWN

    if (this.apiEndpoint && regexReviewReport.test(this.apiEndpoint)) {
      this.fingerprint = REVIEW_REPORT
    }
  }
}
