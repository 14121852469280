export class CardRegistrationError extends Error {
  code: string
  errorType: 'token' | 'card'

  constructor(message: string, { code, errorType }: { code: string, errorType: 'token' | 'card' }) {
    super(message)
    this.code = code
    this.errorType = errorType
  }
}
