import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { BookingMetaState, ListResponse, BookingsGuestDetails, CancelBookingGuestQueryParameters, ExtendedBookingPrices, RequestInfoPricesQueryParameters, AddExtensionsBookingGuestQueryParameters, AllBookingsGuest, PatchRequestPayload, CreateRequestPayload } from '@yescapa-dev/ysc-api-js/legacy'
import { BOOKING_META_STATES, BOOKING_STATES } from '@yescapa-dev/ysc-api-js/modern'
import { DEFAULT_BOOKINGS_PAGE_SIZE } from '~/constants/bookings'

export const useBookingsGuestStore = defineStore('bookingsGuest', () => {
  const { $api } = useYscApi()
  const { getReferentialMaps } = useReferentialStore()

  const {
    TO_DO,
    WAITING,
    CONFIRMED,
    ARCHIVED,
  } = Constants.BOOKINGS.LISTING_STATE

  const all = ref<Record<BookingMetaState, ListResponse<AllBookingsGuest>>>({
    [TO_DO]: { count: 0, results: [] },
    [WAITING]: { count: 0, results: [] },
    [CONFIRMED]: { count: 0, results: [] },
    [ARCHIVED]: { count: 0, results: [] },
  })

  const bookingGuest = ref<BookingsGuestDetails | null>(null)

  const header = ref<{
    id?: string | number | null
    meta_state?: string | null
    date_time_from?: string | null
    date_time_to?: string | null
  }>({
    id: null,
    meta_state: null,
    date_time_from: null,
    date_time_to: null,
  })

  const bookingGuestIsAbandonned = computed(() => {
    return Boolean(bookingGuest.value?.state === BOOKING_STATES.ABANDONED)
  })

  const bookingGuestIsValidated = computed(() => {
    return bookingGuest.value?.state === BOOKING_STATES.VALIDATED
  })

  const bookingGuestIsAccepted = computed(() => {
    return bookingGuest.value?.state === BOOKING_STATES.ACCEPTED
  })

  const bookingGuestIsConfirmed = computed(() => {
    return bookingGuest.value?.meta_state === BOOKING_META_STATES.CONFIRMED
  })

  const bookingGuestIsArchived = computed(() => {
    return bookingGuest.value?.meta_state === BOOKING_META_STATES.ARCHIVED && !bookingGuestIsAbandonned.value
  })

  const bookingGuestCanBePaid = computed(() => {
    return useCanPayBookingGuest({ state: bookingGuest.value?.state })
  })

  const bookingGuestCanBeUpdated = computed(() => {
    return bookingGuestIsAbandonned.value
      || bookingGuestIsAccepted.value
      || (bookingGuestIsValidated.value && Boolean(bookingGuest.value?.is_instant))
  })

  const bookingGuestIsCancelled = computed(() => {
    return bookingGuest.value?.state === BOOKING_STATES.CANCELLED_BOTH
      || bookingGuest.value?.state === BOOKING_STATES.CANCELLED_GUEST
      || bookingGuest.value?.state === BOOKING_STATES.CANCELLED_OWNER
      || bookingGuest.value?.state === BOOKING_STATES.CANCELLED_STAFF
  })

  const { insurances, basicInsurance, premiumInsurance } = useBookingInsurances()

  const selectedInsurance = computed(() => {
    const currentId = bookingGuest.value?.insurance.id
    if (!isDefined(insurances.value) || !isDefined(currentId)) {
      return undefined
    }

    return insurances.value.find(({ id }) => id === currentId)
  })

  const canUpgradeInsurance = computed(() => {
    return Boolean(premiumInsurance.value)
      || Boolean(bookingGuest.value?.permissions.is_buyback_available)
      || Boolean(bookingGuest.value?.permissions.is_rental_cover_available)
  })

  const prices = ref<(ExtendedBookingPrices & { trip_pk: number }) | null>(null)

  const stateEnhanced = computed(() => bookingGuest.value ? getTravelStateEnhanced(bookingGuest.value) : null)

  const permissions = computed<Record<string, boolean>>(() => {
    if (!bookingGuest.value) {
      return {}
    }

    const permissions = {
      ...bookingGuest.value.permissions,
      canShowExternalRefundProcedure: false,
      canPushExtraSearch: false,
      canPayExtension: false,
      canUpdateBookingGuest: false,
      canRefuseBookingGuest: false,
      alertOwnerCancellationDocumentInValidation: false,
      alertBookingGuestUpdateAvailableAfterPayment: false,
    }

    const bookingGuestState = toValue(stateEnhanced)
    const { VALIDATED, ACCEPTED, TO_COME_NOT_PAID, CANCELLED_GUEST } = Constants.BOOKINGS.STATUS
    const { WAITING: META_WAITING, TO_DO: META_TODO } = Constants.BOOKINGS.LISTING_STATE

    permissions.canRefuseBookingGuest = bookingGuestState === VALIDATED || bookingGuestState === ACCEPTED || bookingGuestState === TO_COME_NOT_PAID

    permissions.alertOwnerCancellationDocumentInValidation = bookingGuestState === CANCELLED_GUEST
      && bookingGuest.value.cancel_reason === Constants.BOOKINGS.CANCEL_REASON.GUEST.REASON_OWNER

    permissions.canShowExternalRefundProcedure = bookingGuestState === CANCELLED_GUEST
      && Boolean(bookingGuest.value.cancel_insurance)
      && bookingGuest.value.cancel_reason === Constants.BOOKINGS.CANCEL_REASON.GUEST.REASON_OTHER

    permissions.canPushExtraSearch = bookingGuestState === VALIDATED

    permissions.canPayExtension = bookingGuest.value.extensions.state === Constants.EXTENSIONS.STATUS.ACCEPTED

    permissions.canUpdateBookingGuest = bookingGuest.value.extensions.permissions.can_create_extensions && !bookingGuest.value.emergency_proof_in_validation

    permissions.alertBookingGuestUpdateAvailableAfterPayment = bookingGuest.value.meta_state === META_WAITING || bookingGuest.value.meta_state === META_TODO

    return permissions
  })

  const stateSummary = computed(() => {
    const bookingGuestState = stateEnhanced.value

    if (!bookingGuest.value || !bookingGuestState) {
      return null
    }

    const { VALIDATED, ACCEPTED, TO_COME_NOT_PAID, TO_COME } = Constants.BOOKINGS.STATUS
    const { ONGOING } = STATUS_ENHANCED
    const { CONFIRMED } = Constants.BOOKINGS.LISTING_STATE

    if (([VALIDATED, ACCEPTED, TO_COME_NOT_PAID] as string[]).includes(bookingGuestState)) {
      return STATUS_SUMMARY.PENDING
    }

    if ([TO_COME, ONGOING].includes(bookingGuestState) || bookingGuest.value.meta_state === CONFIRMED) {
      return STATUS_SUMMARY.CONFIRMED
    }

    const { canShowExternalRefundProcedure, alertOwnerCancellationDocumentInValidation } = permissions.value

    if (
      bookingGuest.value.emergency_proof_in_validation
      || canShowExternalRefundProcedure
      || alertOwnerCancellationDocumentInValidation
    ) {
      return STATUS_SUMMARY.WARNING
    }

    return STATUS_SUMMARY.DONE
  })

  const fetchBookingsGuestByState = async ({
    meta_state,
    page = 1,
    page_size = DEFAULT_BOOKINGS_PAGE_SIZE,
    year = undefined,
    state = undefined,
    refresh = false,
  }: {
    meta_state: BookingMetaState
    page?: number
    page_size?: number
    year?: number
    state?: string
    refresh?: boolean
  }) => {
    if (!refresh && !!all.value[meta_state].count) {
      return
    }
    const { $api } = useYscApi()
    const data = await $api.requests.getAll({
      meta_state,
      page,
      page_size,
      year,
      state,
    })

    all.value[meta_state] = data
  }

  const setBookingGuestHeader = ({ id, meta_state, date_time_from, date_time_to }: { id?: number | string, meta_state?: string, date_time_from?: string, date_time_to?: string }) => {
    header.value = { id, meta_state, date_time_from, date_time_to }
  }

  const createBookingGuest = async (payload: CreateRequestPayload) => {
    const data = await $api.requests.createRequest(payload)
    bookingGuest.value = data
    setBookingGuestHeader(data)
  }

  const fetchBookingGuest = async ({ id, refresh = false }: { id: number | string, refresh?: boolean }) => {
    if (!refresh && +id === bookingGuest.value?.id) {
      return Promise.resolve()
    }
    const data = await $api.requests.get(`${id}`)
    bookingGuest.value = data
    setBookingGuestHeader(data)
  }

  const fetchBookingGuestPrices = async ({ id, refresh = false, ...payload }: { id: string | number, refresh?: boolean } & RequestInfoPricesQueryParameters) => {
    if (!refresh && +id === prices.value?.trip_pk) {
      return Promise.resolve()
    }
    const data = await $api.requests.getPrices(id, payload)
    prices.value = {
      ...data,
      trip_pk: +id,
    }
  }

  const formatInsurances = useFormatInsurances()
  const fetchBookingGuestInsurances = async ({ refresh = false }: { refresh?: boolean } = {}) => {
    if (!isDefined(bookingGuest.value)) {
      return Promise.resolve()
    }
    if (!refresh
      && isDefined(insurances.value)
      && insurances.value.map(({ id }) => id).includes(bookingGuest.value.insurance.id)
    ) {
      return Promise.resolve()
    }

    const { choices } = await $api.insurance.get({
      camper_id: bookingGuest.value.camper.id,
      date_from: bookingGuest.value.date_from,
      date_to: bookingGuest.value.date_to,
    })

    insurances.value = formatInsurances({
      insurances: choices ?? [],
      currencies: getReferentialMaps('currency'),
      insurance_id: bookingGuest.value.insurance.id,
    })
  }

  // only used on booking request funnels
  const updateBookingGuest = async ({ form }: { form: PatchRequestPayload }) => {
    const id = bookingGuest.value?.id
    if (!isDefined(id)) {
      return
    }
    bookingGuest.value = await $api.requests.patchRequest(id, form)
  }

  const cancelBookingGuest = async ({ form }: { form: CancelBookingGuestQueryParameters }) => {
    if (bookingGuest.value) {
      bookingGuest.value = await $api.requests.cancel(bookingGuest.value.id, form)
    }
  }

  const createBookingGuestExtension = async ({ form }: { form: AddExtensionsBookingGuestQueryParameters }) => {
    if (!bookingGuest.value) {
      return
    }
    bookingGuest.value = await $api.requests.postExtensionRequest(bookingGuest.value.id, form)
  }

  const deleteBookingGuestExtension = async ({ id }: { id: string | number }) => {
    if (!bookingGuest.value) {
      return
    }
    bookingGuest.value = await $api.requests.deleteExtensionRequest(bookingGuest.value.id, id)
  }

  const validateBookingGuestExtension = async () => {
    if (!bookingGuest.value) {
      return
    }
    bookingGuest.value = await $api.requests.validateExtensions(bookingGuest.value.id)
  }

  const cancelBookingGuestExtension = async () => {
    if (!bookingGuest.value) {
      return
    }
    bookingGuest.value = await $api.requests.cancelExtensions(bookingGuest.value.id)
  }

  return {
    all,
    header,
    prices,
    bookingGuest,
    stateEnhanced,
    stateSummary,
    permissions,
    bookingGuestIsAbandonned,
    bookingGuestIsAccepted,
    bookingGuestIsValidated,
    bookingGuestIsConfirmed,
    bookingGuestIsArchived,
    bookingGuestCanBePaid,
    bookingGuestCanBeUpdated,
    bookingGuestIsCancelled,
    fetchBookingsGuestByState,
    setBookingGuestHeader,
    updateBookingGuest,
    insurances,
    basicInsurance,
    premiumInsurance,
    selectedInsurance,
    canUpgradeInsurance,
    createBookingGuest,
    fetchBookingGuest,
    fetchBookingGuestPrices,
    fetchBookingGuestInsurances,
    cancelBookingGuest,
    createBookingGuestExtension,
    deleteBookingGuestExtension,
    validateBookingGuestExtension,
    cancelBookingGuestExtension,
  }
})
