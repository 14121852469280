import type { PaymentsListItemResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { BOOKING_PAYMENT_METHODS } from '@yescapa-dev/ysc-api-js/modern'
import type { AllNullable } from './commons'

export interface PaymentCardForm {
  cardNumber: string
  expirationDateYear: number
  expirationDateMonth: number
  ccv: string
}

export interface PaymentCardModel {
  cardNumber: string
  expirationDateYear: string
  expirationDateMonth: string
  ccv: string
}

export interface MbwayForm {
  phone: string
}
export interface IdealForm {
  bic: string
}
export interface MbwayForm {
  phone: string
}

export interface PaymentsForms extends Record<AvailablePaymentType, unknown> {
  [BOOKING_PAYMENT_METHODS.CREDIT_CARD]: AllNullable<PaymentCardForm>
  [BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x]: AllNullable<PaymentCardForm>
  [BOOKING_PAYMENT_METHODS.KLARNA]: Record<string, never>
  [BOOKING_PAYMENT_METHODS.PAYPAL]: Record<string, never>
  [BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x]: Record<string, never>
  [BOOKING_PAYMENT_METHODS.IDEAL]: AllNullable<IdealForm>
  [BOOKING_PAYMENT_METHODS.MBWAY]: AllNullable<MbwayForm>
}

export interface PaymentsFormsNew extends Record<AvailablePaymentType, unknown> {
  [BOOKING_PAYMENT_METHODS.CREDIT_CARD]: AllNullable<PaymentCardModel>
  [BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x]: AllNullable<PaymentCardModel>
  [BOOKING_PAYMENT_METHODS.KLARNA]: Record<string, never>
  [BOOKING_PAYMENT_METHODS.PAYPAL]: Record<string, never>
  [BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x]: Record<string, never>
  [BOOKING_PAYMENT_METHODS.IDEAL]: AllNullable<IdealForm>
  [BOOKING_PAYMENT_METHODS.MBWAY]: AllNullable<MbwayForm>
}

/**
 * Payment type which necessitate a card registration flow through MangoPay
 */
export type CardPaymentType = BOOKING_PAYMENT_METHODS.CREDIT_CARD | BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x
export const isCardPayment = (method: string | null): method is CardPaymentType => method !== null && ([BOOKING_PAYMENT_METHODS.CREDIT_CARD, BOOKING_PAYMENT_METHODS.CREDIT_CARD_2x] as string[]).includes(method)

export type WebRedirectPaymentType = BOOKING_PAYMENT_METHODS.CREDIT_CARD_4x | BOOKING_PAYMENT_METHODS.IDEAL | BOOKING_PAYMENT_METHODS.KLARNA | BOOKING_PAYMENT_METHODS.PAYPAL

export type AvailablePaymentType = CardPaymentType | WebRedirectPaymentType | BOOKING_PAYMENT_METHODS.MBWAY

export interface AvailablePayment extends PaymentsListItemResponse {
  payment_type: AvailablePaymentType
}

export enum PaymentErrorCategory {
  MGP_3DS_AUTH_FAILED = 'MGP_3DS_AUTH_FAILED',
  MPG_BANK_AMOUNT_LIMIT_REACHED = 'MPG_BANK_AMOUNT_LIMIT_REACHED',
  MPG_CARD_AMOUNT_LIMIT_REACHED = 'MPG_CARD_AMOUNT_LIMIT_REACHED',
  MPG_CARD_EXPIRED = 'MPG_CARD_EXPIRED',
  MPG_CARD_FRAUD = 'MPG_CARD_FRAUD',
  MPG_CARD_INACTIVE = 'MPG_CARD_INACTIVE',
  MPG_CARD_LOST = 'MPG_CARD_LOST',
  MPG_COUNTRY_UNAUTHORIZED = 'MPG_COUNTRY_UNAUTHORIZED',
  MPG_AUTH_EXPIRED = 'MPG_AUTH_EXPIRED',
  MPG_PAYPAL_FAILED = 'MPG_PAYPAL_FAILED',
  MPG_UNKNOWN = 'MPG_UNKNOWN',
  YSC_PAYMENTS = 'YSC_PAYMENTS',
  YSC_PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END = 'YSC_PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END',
  YSC_PAYMENT_CONFIMRATION_TOO_LONG = 'YSC_PAYMENT_CONFIMRATION_TOO_LONG',
}

export enum STATES_UI_PAYMENT {
  IDLE,
  PREPARATION,
  EXECUTION,
  FINALIZATION,
  SYNCHRONISATION,
  MB_WAY_EXECUTION,
  MB_WAY_FINALIZATION,
  COMPLETED,
}
