<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'

const { toasts } = storeToRefs(useToastsStore())

watchDebounced(
  toasts,
  () => {
    toasts.value.shift()
  },
  { debounce: 4000, deep: true, immediate: true },
)
</script>

<template>
  <div>
    <ClientOnly>
      <Teleport
        v-if="toasts.length"
        to="body"
      >
        <TransitionGroup
          name="slide"
          tag="div"
          class="fixed bottom-4 ml-4 right-4 space-y-2 max-w-96 z-[999999999]"
        >
          <AppToast
            v-for="error in toasts"
            :key="error.timestamp"
            :error="error"
          />
        </TransitionGroup>
      </Teleport>
    </ClientOnly>
  </div>
</template>
