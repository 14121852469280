import type { EarningSimulationPayload, EarningSimulation } from '@yescapa-dev/ysc-api-js/legacy'
import type { AllNullable } from '~/types/commons'
import { assertAllNullableTypeIsType } from '~/utils/assertAllNullableTypeIsType'

export const useEarningsStore = defineStore('earnings', () => {
  const params = ref<AllNullable<EarningSimulationPayload>>({
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    country: null,
    is_professional: null,
    vehicle_type: null,
    vehicle_availability: null,
    vehicle_registration_country: null,
    number_of_weeks: undefined,
    number_of_weekends: undefined,
    vehicle_registered_year: null,
    number_of_seats: null,
    lead_source: null,
  })

  const results = ref<EarningSimulation | null>(null)

  const { $api } = useYscApi()

  const simulateEarnings = async () => {
    const paramsValue = toValue(params)
    try {
      assertAllNullableTypeIsType<EarningSimulationPayload>(paramsValue, 'first_name', 'last_name', 'email', 'vehicle_type', 'vehicle_registration_country', 'is_professional', 'vehicle_availability', 'vehicle_registration_country')
    }
    catch (_e) {
      return
    }
    const data = await $api.earnings.post(paramsValue)
    results.value = data
    if (data.number_of_weekends) {
      params.value.number_of_weekends = data.number_of_weekends
    }
    if (data.number_of_weeks) {
      params.value.number_of_weeks = data.number_of_weeks
    }
  }

  const fetchLeadSource = async (id: number) => {
    await $api.leadSource.get(id)
    params.value.lead_source = id
  }

  return {
    params,
    results,
    simulateEarnings,
    fetchLeadSource,
  }
})
