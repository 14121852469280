export const useGoboonyRedirectCreateCamper = (ownerLanguage: MaybeRefOrGetter<string | undefined>) => {
  const { localeProperties } = useI18n()

  const goboonyCreateCamperUrl = computed(() => {
    const baseUrl: Record<string, string> = {
      'nl-BE': 'https://www.goboony.be/camper-verhuren-via-goboony',
      'de': 'https://www.goboony.de/wohnmobil-vermieten-ueber-goboony',
      'en': 'https://www.goboony.co.uk/list-your-motorhome-on-goboony',
      'fr': 'https://www.goboony.fr/inscrire-votre-camping-car',
      'it': 'https://www.goboony.it/pubblica-il-tuo-camper-su-goboony',
      'nl': 'https://www.goboony.nl/camper-verhuren-via-goboony',
    }

    const ownerLanguageValue = toValue(ownerLanguage)

    if (!ownerLanguageValue) {
      return baseUrl.en
    }

    const topLevelLocal = getTopLevelLocale(ownerLanguageValue ?? 'en')

    const origin = ownerLanguageValue in baseUrl ? baseUrl[ownerLanguageValue] : (topLevelLocal in baseUrl ? baseUrl[topLevelLocal] : baseUrl.en)
    const searchParams = new URLSearchParams({
      target: '_blank',
      rel: 'nofollow',
      utm_campain: ownerLanguageValue == 'nl' ? 'netherlands' : 'united-kingdom',
      utm_medium: 'onboarding',
      utm_source: localeProperties.value.domain ?? '',
      flavor: localeProperties.value.domain ?? '',
    })

    const url = new URL(`${origin}?${searchParams}`)

    return url.toString()
  })

  return {
    goboonyCreateCamperUrl,
  }
}
